@tailwind base;
@tailwind components;
@tailwind utilities;
@import "../components/modules/webform/webform-style.css";
/*@import "../public/fonts/fonts.css";
@import "../public/fonts/tifinagh-fonts.css";
*/

@media (min-width: 768px) {
	.md\:max-h-none {
		max-height: none !important;
	}
}

body {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

.wysiwyg,
.wysiwyg div {
	display: flex;
	flex-direction: column;
	gap: 0.7rem;
}

.wysiwyg a {
	@apply text-secondary;
}

.wysiwyg a:hover {
	@apply text-secondary underline;
}

.wysiwyg h6 {
	@apply text-secondary;
	margin: 0;
}

/* global white heading */
.white-heading h1,
.white-heading h2,
.white-heading h3,
.white-heading h4,
.white-heading h5,
.white-heading h6 {
	color: #fff;
}

.obligatoires span {
	@apply text-red-500;
}

/* conseils */
.conseils-areas {
	grid-template-areas:
		"a a a a b b b c c c"
		"a a a a b b b c c c"
		/* "a a a a b b b c c c" */
		"a a a a d d d d d d"
		"a a a a d d d d d d";
}
.area-0 {
	grid-area: a;
}
.area-1 {
	grid-area: b;
}
.area-2 {
	grid-area: c;
}
.area-3 {
	grid-area: d;
}
.area-4 {
	grid-area: e;
}
.shadow-card {
	box-shadow: 0 2px 5px 0 rgb(0, 0, 0, 0.3);
}
/* conseils */

@layer base {
	html[dir="rtl"] body {
		@apply font-rtl;
	}

	html[lang="Tfng"] body {
		@apply !font-abudrar;
	}

	html[dir="ltr"] .font-bold,
	html[dir="ltr"] .font-700,
	html[dir="ltr"] .font-semibold,
	html[dir="ltr"] .font-600,
	html[dir="ltr"] .font-sans.font-bold,
	html[dir="ltr"] .font-sans.font-700,
	html[dir="ltr"] .font-sans.font-semibold,
	html[dir="ltr"] .font-sans.font-600 {
		font-family: "DINNextBold";
	}

	html[dir="ltr"] .font-sans.font-normal,
	html[dir="ltr"] .font-sans.font-500 {
		font-family: "DINNextRegular";
	}

	html[dir="ltr"] .font-sans.font-medium,
	html[dir="ltr"] .font-sans.font-500 {
		font-family: "DINNextMedium";
	}

	html[dir="ltr"] .font-sans.font-black,
	html[dir="ltr"] .font-sans.font-900,
	html[dir="ltr"] .font-extrabold {
		font-family: "DINNextHeavy";
	}

	html[lang="Tfng"] .font-bold,
	html[lang="Tfng"] .font-700,
	html[lang="Tfng"] .font-semibold,
	html[lang="Tfng"] .font-600,
	html[lang="Tfng"] .font-sans.font-bold,
	html[lang="Tfng"] .font-sans.font-700,
	html[lang="Tfng"] .font-sans.font-semibold,
	html[lang="Tfng"] .font-sans.font-600,
	html[lang="Tfng"] .font-sans.font-normal,
	html[lang="Tfng"] .font-sans.font-500,
	html[lang="Tfng"] .font-sans.font-medium,
	html[lang="Tfng"] .font-sans.font-500,
	html[lang="Tfng"] .font-sans.font-black,
	html[lang="Tfng"] .font-sans.font-900,
	html[lang="Tfng"] .font-extrabold {
		@apply !font-abudrar;
	}

	.text-align-left {
		@apply text-left;
	}
	.text-align-center {
		@apply text-center;
	}
	.text-align-right {
		@apply text-right;
	}
	.text-align-justify {
		@apply text-justify;
	}

	.screen-disable__scroll {
		@apply fixed h-screen overflow-hidden;
	}

	[dir="rtl"] .rtl-icon {
		transform: rotateY(180deg);
	}

	.container .container {
		@apply px-0;
	}

	.container .container-fluid {
		@apply px-0;
	}

	.container-fluid .container {
		@apply px-0;
	}
}

@layer components {
	.custom-list {
		list-style: none;
		/* padding-left: 20px; */
	}
	.custom-list li {
		position: relative;
		/* padding-left: 42px; */
		padding-left: 25px;
		/* margin-bottom: 16px; */
		line-height: 1;
	}
	.custom-list li:not(:last-child) {
		margin-bottom: 16px;
	}
	.custom-list li::before {
		content: "";
		display: inline-block;
		width: 16px;
		height: 16px;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		background-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2Ij4KPGcgaWQ9InRpY2stY2lyY2xlIj4KPHBhdGggaWQ9IlZlY3RvciIgZD0iTTEyIDIyQzE3LjUgMjIgMjIgMTcuNSAyMiAxMkMyMiA2LjUgMTcuNSAyIDEyIDJDNi41IDIgMiA2LjUgMiAxMkMyIDE3LjUgNi41IDIyIDEyIDIyWiIgc3Ryb2tlPSIjRkY1RTAwIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGlkPSJWZWN0b3JfMiIgZD0iTTcuNzUgMTEuOTk5OUwxMC41OCAxNC44Mjk5TDE2LjI1IDkuMTY5OTIiIHN0cm9rZT0iI0ZGNUUwMCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L2c+Cjwvc3ZnPg==");
	}

	.custom-list ul li {
		position: relative;
		padding-left: 18px;
		margin-top: 10px;
	}

	.custom-list ul li::before {
		content: "";
		display: inline-block;
		width: 10px;
		height: 10px;
		position: absolute;
		left: 0;
		top: 5px;
		background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAjSURBVHgB7coxAQAACAIwtJldDG8LqcDBye7V7TwEDVGiJxIfowKgUR0bHgAAAABJRU5ErkJggg==");
	}
}

input[type="range"].defaultInputRangeThumb::-webkit-slider-thumb {
	-webkit-appearance: none;
	height: 20px;
	width: 10px;
	border-radius: 5px;
	background: #0077ff;
	cursor: pointer;
	box-shadow: 0 0 2px 0 #555;
	transition: background 0.3s ease-in-out;
}

input[type="range"].roundedInputRangeThumb::-webkit-slider-thumb {
	-webkit-appearance: none;
	height: 20px;
	width: 20px;
	border-radius: 50%;
	background: #0077ff;
	cursor: pointer;
	box-shadow: 0 0 2px 0 #555;
	transition: background 0.3s ease-in-out;
}
.paragraphs p {
	margin-block: 2rem;
}

.card-paragraph {
	@apply text-[16px] mb-[25px] leading-6 line-clamp-3;
}
.card-header {
	@apply text-[22.5px] font-sans line-clamp-2 leading-[31px] tracking-[1px] font-semibold mb-[18px];
}
.word-break-all {
	word-break: break-all;
}

#nprogress .bar {
	@apply bg-primary;
}

#nprogress .spinner {
	@apply left-1/2 top-12 -translate-x-1/2 rtl:translate-x-1/2 w-max px-5 py-3 shadow-md flex items-center gap-3 text-sm text-black bg-white;
}
.cookie-wrapper p,
.cookie-wrapper li {
	@apply !mb-0 text-[12px] leading-4;
}
.cookie-wrapper .custom-list {
	@apply pl-2;
}
.cookie-wrapper li {
	@apply pl-4 rtl:pr-4;
}
.cookie-wrapper .wysiwyg,
.cookie-wrapper .wysiwyg div {
	gap: 2px;
}

.cookie-wrapper .custom-list li::before {
	@apply w-[10px] h-[10px] bg-contain;
}

.slogan.text-base {
	font-size: 22px;
	line-height: 32px;
}

@media (max-width: 1200px) {
	.slogan.text-base {
		font-size: 18px;
		line-height: 26px;
	}
}
@media (max-width: 768px) {
	.slogan.text-base {
		font-size: 13px;
		line-height: 20px;
	}
}

.wrapper-conteneur {
	padding: 30px 0 20px 0;
	border-width: 2px 0;
	margin: 40px 0;
}
.wrapper-conteneur > .w-full > .wysiwyg p {
	@apply text-base !important;
	margin-bottom: 0;
}

.lp_full-bg {
	position: relative;
	overflow: hidden;
}

.lp_full-bg p {
	font-size: 14px !important;
}

.lp_full-bg::after {
	position: absolute;
	content: "";
	z-index: -1;
	background-color: #ffbd00;
	height: 100%;
	width: 200vw;
	top: 0;
	left: -30%;
}

.lp-slider_title h1 {
	@apply text-[28px] md:text-[32px] lg:text-5xl overflow-hidden block leading-8 md:leading-[50px] rtl:md:leading-[80px];
}

.lp-slider_title h1 strong {
	@apply text-primary;
}

.lp-slider_avantages p {
	@apply text-[16px] md:text-[20px] md:text-[22px] mb-5 md:mb-10;
}

.lp-slider_avantages ul {
	display: flex;
	justify-content: space-between;
	list-style-type: none;
	counter-reset: compteListe;
	padding: 0;
}

.lp-slider_avantages li {
	@apply text-center pl-0 !mb-0 font-bold text-xs md:text-sm mx-1 flex-1;
}

.lp-slider_avantages li::before {
	counter-increment: compteListe;
	content: counter(compteListe) " ";
	background-image: none;
	@apply relative block w-auto h-auto top-0 pb-5 text-[50px] md:text-[80px] text-primary font-sans font-black;
	transform: translateY(0%);
}

.lp-slider_avantages li:not(:last-of-type)::after {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: calc(100% - 20px);
	width: 50px;
	height: 8px;
	background-image: url("../public/assets/img/path-lp.png");
	background-repeat: no-repeat;
	background-size: auto 100%;
	z-index: 1;
}

html[dir="rtl"] [type="tel"]::placeholder {
	text-align: right;
}
